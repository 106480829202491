/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
// import './buscarcep';
import select2 from 'select2';
import 'select2/dist/css/select2.css';
import { initStarRating } from '../plugins/init_star_rating';

import Inputmask from "inputmask";

document.addEventListener("turbolinks:load", () => {
  //Multiselect init
  $('.multiselect-liga').each( function(e) {
    $(this).select2();
  })

  const limitedTo3 = "#product_data_11, #traction_data_19, #startup_challenge_product_data_11, #startup_challenge_traction_data_19"

  $(limitedTo3).each( function(e) {
    $(this).select2({
      maximumSelectionLength: 3
    });  
  })
  

  initStarRating();

  Inputmask({'showMaskOnHover': false, 'showMaskOnFocus': false, 'greedy': false}).mask(document.querySelectorAll("input"));

  //Textarea auto-resize
  document.querySelectorAll('textarea').forEach(el => {
    el.style.height = el.setAttribute('style', 'height: ' + el.scrollHeight + 'px');
    el.addEventListener('input', e => {
      if(+el.style.height.replace('px', '') <= el.scrollHeight) {
        el.style.height = (el.scrollHeight) + 'px';
      }
    });
  });
  
  //Text-fade-out
  const challengeDescription = document.querySelector('.description-text')
  if(challengeDescription) {
    if (challengeDescription.scrollHeight > challengeDescription.offsetHeight) {
      document.querySelector('.description-fade-out').classList.remove('d-none') 
    }
  }

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  // Add placeholder to select input - startup founder time dedication
const dedicationOption = $('.founder-dedication div select')[0]
  if (dedicationOption) {
    if (dedicationOption.selectedOptions[0].innerText === "") {
      dedicationOption.innerHTML = "<option value='' disabled selected>Dedicação</option> <option value='0'>fulltime</option> <option value='1'>part-time</option>"
  }}
})

